/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import PricingCard from './pricingCard';
import HeaderNavigation from './headerNavigation';
import HeaderNavigationImage from './headerNavigationImage';
import { setSubscriptionBanner } from '../../redux/services';
import { allHomeBanners } from '../../api/coupon';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

const PricingPlans = () => {
    const [monthly, setMonthly] = useState(true);

    const planDetails = {
        free: {
            title: 'Free Plan',
            subTitle: 'Ideal for users starting with service scheduling and bookings',
            monthlyPrice: 'Free',
            yearlyPrice: 'Free',
            features: [
                { name: "Service Schedule", description: "Manage your service schedules effectively." },
                { name: "Service Booking", description: "Book services quickly and effortlessly." }
            ],
        },
        basic: {
            title: 'Basic Plan',
            subTitle: 'Suitable for businesses that need basic scheduling and invoicing',
            monthlyPrice: '$5.95/month',
            yearlyPrice: '$69.95/year',
            features: [
                { name: "Service Schedule", description: "Manage your service schedules effectively." },
                { name: "Service Booking", description: "Book services quickly and effortlessly." },
                { name: "Estimates", description: "Create and manage cost estimates for your services." },
                { name: "Invoice", description: "Generate and manage invoices for clients." }
            ],
        },
        standard: {
            title: 'Standard Plan',
            subTitle: 'Great for businesses needing contracts, payments, and scheduling',
            monthlyPrice: '$9.95/month',
            yearlyPrice: '$119.95/year',
            features: [
                { name: "Service Schedule", description: "Manage your service schedules effectively." },
                { name: "Service Booking", description: "Book services quickly and effortlessly." },
                { name: "Estimates", description: "Create and manage cost estimates for your services." },
                { name: "Invoice", description: "Generate and manage invoices for clients." },
                { name: "Service Timing Schedule", description: "Set and manage specific service timings." },
                { name: "Sign Contract", description: "Sign and manage digital contracts." },
                { name: "Payment Online", description: "Accept online payments securely." }
            ],
        },
        premium: {
            title: 'Premium Plan',
            subTitle: 'Perfect for businesses that want full employee tracking capabilities',
            monthlyPrice: '$15.95/month',
            yearlyPrice: '$189.95/year',
            features: [
                { name: "Service Schedule", description: "Manage your service schedules effectively." },
                { name: "Service Booking", description: "Book services quickly and effortlessly." },
                { name: "Estimates", description: "Create and manage cost estimates for your services." },
                { name: "Invoice", description: "Generate and manage invoices for clients." },
                { name: "Service Timing Schedule", description: "Set and manage specific service timings." },
                { name: "Sign Contract", description: "Sign and manage digital contracts." },
                { name: "Payment Online", description: "Accept online payments securely." },
                { name: "Track the Employee Time", description: "Track employee time and attendance." }
            ],
        },
    };

    const pricingData = Object.keys(planDetails).map((key) => ({
        title: planDetails[key].title,
        subTitle: planDetails[key].subTitle,
        list: planDetails[key].features,
        monthly: planDetails[key].monthlyPrice,
        annual: planDetails[key].yearlyPrice,
    }));


    const subscriptionBanner = useSelector((state) => state.services?.subscriptionBanner);
    const dispatch = useDispatch();

    const fetchFaqs = useCallback(
        debounce(async () => {
            try {
                const res = await allHomeBanners('subscription', '');
                if (res?.data) {
                    dispatch(setSubscriptionBanner(res?.data?.banners));
                }
            } catch (error) {
                console.error("Error fetching banners", error);
            }
        }, 500),
        []
    );

    useEffect(() => {
        if (!subscriptionBanner?.length) {
            fetchFaqs();
        } else {
            dispatch(setSubscriptionBanner(subscriptionBanner));
        }
        return () => {
            fetchFaqs.cancel();
        };
    }, [subscriptionBanner]);

    return (
        <main className="pb-5">
            <HeaderNavigationImage data={subscriptionBanner[0]} />
            <div className="mt-8 flex flex-col items-center my-5">
                <div className="rounded-xl bg-light p-0.5">
                    <div className="relative grid grid-cols-[1fr,1fr] gap-x-0.5">
                        <div
                            className={`bg-white absolute left-0 top-0 h-full w-[calc((100%-2px)/2)] rounded-[10px] bg-primary transition-transform duration-500 ease-in-out shadow-[0px_4px_4px_-2px_rgba(24,_39,_75,_0.06),_0px_2px_4px_-2px_rgba(24,_39,_75,_0.02),0px_0px_2px_0px_#E0E0E0] ${!monthly && 'translate-x-[calc(100%+2px)]'
                                }`}
                        ></div>
                        <button
                            className={`isolate plusJakara_medium text-sm transition-colors duration-500 ease-emphasized-in-out rounded-[10px] px-4 py-2 ${monthly ? 'text-black-400' : 'text-black-700'
                                }`}
                            disabled={monthly}
                            onClick={() => {
                                setMonthly(true);
                            }}
                        >
                            Monthly
                        </button>
                        <button
                            disabled={!monthly}
                            onClick={() => {
                                setMonthly(false);
                            }}
                            className={`isolate plusJakara_medium text-sm transition-colors duration-500 ease-emphasized-in-out rounded-[10px] px-4 py-2 ${monthly ? 'text-black-700' : 'text-black-400'
                                }`}
                        >
                            Annual
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex gap-3 flex-wrap justify-center items-stretch mt-4 mb-5">
                {pricingData?.map((item, index) => (
                    <div className="" key={index}>
                        <PricingCard
                            list={item.list}
                            title={item.title}
                            monthly={monthly}
                            selected={index === 1}
                            subTitle={item.subTitle}
                            price={monthly ? item.monthly : item.annual}
                        />
                    </div>
                ))}
            </div>
        </main>
    );
};

export default PricingPlans;
