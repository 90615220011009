/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Menu, Transition } from "@headlessui/react";
import { Dropdown, message } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { IoMenu } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { avataruser, chat } from '../icons/icon';
import NotificationDropdown from "../pages/common/NotificationDropdown";
import { useSocket } from "../pages/messages/socketProvider";
import { setAccessToken, setLogin, setUserData, setUserType } from '../redux/loginForm';
const NavHeader = () => {
    const socket = useSocket()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isLogin = useSelector((state) => state?.auth?.isLogin)
    const pagesCount = useSelector((state) => state.services?.pagesCount);
    const userData = useSelector((state) => state?.auth?.userData)
    const [selectedLink, setSelectedLink] = useState('1');
    const [show, setShow] = useState(false);
    const [count, setcount] = useState(0)
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (socket) {
            socket.on("conversation-count", (data) => {
                setcount(data?.count)
            });
        }
    }, [socket])

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            const isScrollingUp = currentScrollPos > prevScrollPos;

            setPrevScrollPos(currentScrollPos);

            if (isScrollingUp) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    const handleLogout = () => {
        console.log('Logging out');
        dispatch(setLogin(false));
        dispatch(setUserType(''));
        dispatch(setAccessToken(''));
        dispatch(setUserData(null));
        message.success('Successfully logged out');
        navigate('/', { replace: true });
    };

    let menuItems;
    if (isLogin === true) {
        menuItems = [
            { id: "1", items: "Home", path: '/', badge: false },
            { id: "2", items: "Schedule", path: '/schedule', badge: pagesCount?.schedule > 0 ? pagesCount?.schedule : null },
            { id: "2", items: "Services", path: '/all-services', badge: pagesCount?.service > 0 ? pagesCount?.service : null },
            { id: "4", items: "Estimates", path: "/estimates", badge: pagesCount?.estimate > 0 ? pagesCount?.estimate : null },
            { id: "3", items: "Contracts", path: '/contracts', badge: pagesCount?.contract > 0 ? pagesCount?.contract : null },
            { id: "5", items: "Orders", path: "/orders", badge: pagesCount?.order > 0 ? pagesCount?.order : null },
            { id: "5", items: "Invoice", path: "/invoices", badge: pagesCount?.invoice > 0 ? pagesCount?.invoice : null },
        ];
    } else {
        menuItems = [
            { id: "1", items: "Home", path: '/' },
            { id: "4", items: "About us", path: "/about" },
            { id: "2", items: "Services", path: '/all-services' },
            // { id: "3", items: "Category", path: '/feature' },
            { id: "5", items: "Contact", path: "/contact" },
        ];
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleLinkClick = (itemId) => {
        setSelectedLink(itemId);
        handleClose();
    };

    const menu = (
        <div className="py-2 bg_white border rounded flex flex-col align-items-center shadow-sm">
            <button
                onClick={() => {
                    dispatch(setUserType("customer"))
                    navigate("/signup");
                }}
                style={{ padding: '10px 16px' }} className="w-full text-start text_black border-b border-b-slate-300 hover:text-[#003F7D] plusJakara_medium">
                Continue as Customer
            </button>
            <button
                onClick={() => {
                    dispatch(setUserType("company"))
                    navigate("/signup");
                }}
                style={{ padding: '10px 16px' }} className="w-full text-start text_black hover:text-[#003F7D] plusJakara_medium">
                Continue as Service Provider
            </button>
        </div >
    );

    const menu2 = (
        <div className="py-2 bg_white border rounded flex flex-col align-items-center shadow-sm">
            <button
                onClick={() => {
                    dispatch(setUserType("customer"))
                    navigate("/login");
                }}
                style={{ padding: '10px 16px' }} className="w-full text-start text_black border-b border-b-slate-300 hover:text-[#003F7D] plusJakara_medium">
                Continue as Customer
            </button>
            <button
                onClick={() => {
                    dispatch(setUserType("company"))
                    navigate("/login");
                }}
                style={{ padding: '10px 16px' }} className="w-full text-start text_black border-b border-b-slate-300 hover:text-[#003F7D] plusJakara_medium">
                Continue as Service Provider
            </button>
            <button
                onClick={() => {
                    dispatch(setUserType("employee"))
                    navigate("/login");
                }}
                style={{ padding: '10px 16px' }} className="w-full text-start text_black hover:text-[#003F7D] plusJakara_medium">
                Continue as Employee
            </button>
        </div>
    );

    return (
        <>
            <Navbar
                expand="lg"
                style={{ zIndex: 999 }}
                className={`py-3 main_nav z-50 ${isScrolled ? 'scrolled' : ''}`}
                id="navbar"
            >
                <Container>
                    <div className="d-flex justify-content-between align-items-center w-full">
                        <Navbar.Brand>
                            <Link to='/' style={{ textDecoration: "none" }} className='text_primary2'>
                                <h5 className="plusJakara_medium mb-0 text-[16px] md:text-xl text_primary2">Request Service</h5>
                            </Link>
                        </Navbar.Brand>
                        <Nav className="w-full">
                            <div className="w-full justify-content-end align-items-center d-flex">
                                <div className="d-flex gap-3">
                                    <div className='d-none d-lg-flex align-items-center gap-2'>
                                        {menuItems.map((item, i) => (
                                            <NavLink
                                                key={i}
                                                className={`nav-lin relative no-underline hover:underline hover:decoration-[#003F7D] px-3 ${location.pathname === item.path ? 'text_primary plusJakara_semibold' : 'text_secondary plusJakara_regular'}`}
                                                to={item.path}
                                                onClick={() => handleLinkClick(item.id)}
                                            >
                                                {item.items}
                                                {item.badge && (
                                                    <span
                                                        className={`rounded-5 text-sm text_white ${item.badge > 9 ? 'px-1 py-[2px]' : 'px-[6px]'} plusJakara_medium`}
                                                        style={{ position: 'absolute', top: '-8px', right: '-4px', backgroundColor: '#003F7D' }}
                                                    >
                                                        {item.badge > 9 ? '+9' : item.badge}
                                                    </span>
                                                )}
                                            </NavLink>
                                        ))}
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        {isLogin === true ?
                                            <>
                                                <Menu as="div" className="relative">
                                                    <Menu.Button className="relative flex align-items-center no-underline">
                                                        <img src={userData?.profilePicture || avataruser} style={{ height: '40px', width: '40px', objectFit: 'cover', borderRadius: '50%' }} alt="" />
                                                    </Menu.Button>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items
                                                            className="absolute z-10 flex border flex-col shadow-sm bg_white rounded-3 py-2"
                                                            style={{
                                                                minWidth: "10rem",
                                                                left: '-10px'
                                                            }}
                                                        >
                                                            <Link
                                                                to='profile'
                                                                style={{ textDecoration: "none" }}
                                                                className="px-4 py-2 plusJakara_semibold sub_grid_dashboard2 text_dark no-underline"
                                                            >
                                                                Profile
                                                            </Link>
                                                            <button
                                                                onClick={handleLogout}
                                                                style={{ textDecoration: "none" }}
                                                                className="px-4 py-2 justify-start flex plusJakara_semibold sub_grid_dashboard2 text_dark no-underline"
                                                            >
                                                                Sign out
                                                            </button>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                                <NotificationDropdown />
                                                <div className="relative">
                                                    <button onClick={() => navigate('/chat')}>
                                                        <div className="relative">
                                                            {pagesCount.conversation > 0 && (
                                                                <span
                                                                    className={`rounded-5 text-sm text_white ${pagesCount?.conversation > 9 ? 'px-1 py-[2px]' : 'px-[6px]'} plusJakara_medium`}
                                                                    style={{ position: 'absolute', zIndex: 99, top: '0px', right: '-4px', backgroundColor: '#003F7D' }}
                                                                >
                                                                    {pagesCount?.conversation > 9 ? '+9' : pagesCount?.conversation}
                                                                </span>
                                                            )}
                                                            <img src={chat} style={{ width: "40px", height: '40px', objectFit: 'cover' }} className="relative" alt="" />
                                                        </div>
                                                    </button>
                                                </div>
                                            </> : <>
                                                <Dropdown
                                                    overlay={menu2}
                                                >
                                                    <button style={{ border: '1px solid #003F7D' }} className="bg_white rounded-2 text_primary plusJakara_regular px-3 px-md-4 max-md:text-xs py-2">
                                                        Login
                                                    </button>
                                                </Dropdown>
                                                <Dropdown
                                                    overlay={menu}
                                                >
                                                    <button className="bg_primary whitespace-nowrap rounded-2 text_white plusJakara_regular px-3 px-md-4 max-md:text-xs py-2">
                                                        Sign up
                                                    </button>
                                                </Dropdown>
                                            </>}
                                    </div>
                                </div>
                                <div className='flex justify-center align-items-center'>
                                    <Navbar.Toggle onClick={handleShow} className='ms-2 border-0 p-0'>
                                        <IoMenu size={28} />
                                    </Navbar.Toggle>
                                </div>
                            </div>
                        </Nav>
                        <Nav>
                            <Offcanvas placement='top' show={show} scroll onHide={handleClose} className="pb-3 min-h-[25rem] w-full visible" >
                                <div className="d-flex justify-content-between px-2 mb-4">
                                    <h5 className="plusJakara_medium mt-3 text_primary2">Request Service</h5>
                                    <Offcanvas.Header closeButton>
                                    </Offcanvas.Header>
                                </div>
                                <Nav className="gap-[20px] flex align-items-center w-full justify-center">
                                    {menuItems.map((item, i) => (
                                        <NavLink
                                            key={i}
                                            className={`nav-lin relative no-underline px-3 ${location.pathname === item.path ? 'text_primary2 plusJakara_semibold' : 'text_secondary plusJakara_regular'}`}
                                            to={item.path}
                                            onClick={() => handleLinkClick(item.id)}
                                        >
                                            {item.items}
                                            {item.badge && (
                                                <span
                                                    className={`rounded-5 text-sm text_white ${item.badge > 9 ? 'px-1 py-[2px]' : 'px-[6px]'} plusJakara_medium`}
                                                    style={{ position: 'absolute', top: '-8px', right: '-4px', backgroundColor: '#003F7D' }}
                                                >
                                                    {item.badge > 9 ? '+9' : item.badge}
                                                </span>
                                            )}
                                        </NavLink>
                                    ))}
                                </Nav>
                            </Offcanvas>
                        </Nav>
                    </div>
                </Container>
            </Navbar >
        </>
    );
};

export default NavHeader;