/* eslint-disable react-hooks/exhaustive-deps */
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Container } from 'reactstrap';
import { allFaqCategories, allfaqs } from '../api/category';
import "react-loading-skeleton/dist/skeleton.css";
import cleaningImage from '../assets/png/cleaning12.jpg';
import { allHomeBanners } from '../api/coupon';
import { setFaqBanner } from '../redux/services';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import HeaderNavigationImage from './common/headerNavigationImage';

const Faq = () => {
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectCategory, setselectCategory] = useState('')
    const [lastId, setLastId] = useState(1);
    const [categories, setCategories] = useState([])
    const [count, setCount] = useState(0);
    const [openIndex, setOpenIndex] = useState(null);

    const fetchData = async () => {
        setLoading(true);
        try {
            const res = await allfaqs(lastId, selectCategory?._id);
            if (res?.data) {
                if (lastId === 1) {
                    setFaqs(res?.data?.Faqs);
                } else {
                    setFaqs((prevFaqs) => [...prevFaqs, ...res?.data?.Faqs]);
                }
                setCount(res?.data?.count?.totalPage || 0);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const handleShowMore = () => {
        setLastId(lastId + 1);
    };

    const fetchCategories = async () => {
        setLoading(true);
        try {
            const res = await allFaqCategories(1)
            if (res?.data) {
                setCategories(res?.data?.faqsCategories);
                setselectCategory(res?.data?.faqsCategories[0])
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, [lastId]);

    useEffect(() => {
        if (selectCategory) {
            fetchData();
        }
    }, [lastId, selectCategory]);

    const toggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const faqBanner = useSelector((state) => state.services?.faqBanner);
    const dispatch = useDispatch();

    const fetchFaqs = useCallback(
        debounce(async () => {
            try {
                const res = await allHomeBanners('FAQ', '');
                if (res?.data) {
                    dispatch(setFaqBanner(res?.data?.banners));
                }
            } catch (error) {
                console.error("Error fetching banners", error);
            }
        }, 500),
        []
    );

    useEffect(() => {
        if (!faqBanner?.length) {
            fetchFaqs();
        } else {
            dispatch(setFaqBanner(faqBanner));
        }
        return () => {
            fetchFaqs.cancel();
        };
    }, [faqBanner]);

    return (
        <main className='pb-5 home_header_width m-auto'>
            <HeaderNavigationImage data={faqBanner[0]} />
            {/* <div className='flex items-center justify-center' style={{
                marginTop: '5rem',
                backgroundImage: `url(${cleaningImage})`,
                backgroundSize: 'cover',
                backgroundRepeat: "no-repeat",
                height: '30rem',
                backgroundPosition: 'top center',
                overflow: 'hidden',
                borderRadius: '24px',
                position: 'relative',
            }}>
                <div className="bg-overlay2"></div>
                <Container className="flex relative z-50 m-auto justify-center">
                    <div className="flex flex-col items-center justify-center max-w-4xl gap-4">
                        <h6 className="text-2xl md:text-4xl mb-0 plusJakara_bold text_white">
                            Frequently Asked Questions
                        </h6>
                        <h6 className="text-[#f4f4f4] md:text-[18px] plusJakara_regular text-center">
                            Keep your home spotless and welcoming with our expert cleaning services.
                            From living rooms to kitchens, we ensure every corner shines.
                            Save time and enjoy peace of mind with a reliable, hassle-free experience.
                            Book now for a cleaner, fresher home today.
                        </h6>
                    </div>
                </Container>
            </div> */}
            <Container className='py-5'>
                <Row gutter={16} className='gap-4'>
                    <Col xs={12} md={4} lg={3}>
                        <div className="flex flex-col gap-2 items-start">
                            <ul className="flex flex-col gap-2">
                                {categories?.map((category, index) => (
                                    <li key={index} className="flex gap-2 justify-start items-start">
                                        <button style={{ minWidth: '12rem' }} onClick={() => setselectCategory(category)} className={`px-[12px] py-[10px] ${selectCategory?._id === category?._id ? 'text_white bg_primary' : 'text_black'} plusJakara_medium text-start no-underline rounded-3`}>
                                            {category?.name}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Col>
                    <Col xs={12} md={4} lg={18}>
                        <div className="flex flex-col w-full lg:w-4/5 mx-auto items-center">
                            <div className="flex flex-col mb-3 gap-1 w-full">
                                <h6 className="text_primary2 plusJakara_medium text-sm">FAQs</h6>
                                <h1 className="text_primary plusJakara_semibold">{selectCategory?.name}</h1>
                                <h6 className="text_secondary2 plusJakara_medium">{selectCategory?.description}</h6>
                            </div>
                            {loading ? (
                                <div className="d-flex w-full mx-auto justify-center">
                                    <div className="w-full">
                                        {Array(2).fill().map((_, index) => (
                                            <div key={index} className="mb-4">
                                                <Skeleton height={40} />
                                                <div className="mt-2">
                                                    <Skeleton count={2} height={20} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : faqs?.length === 0 ? (
                                <div className="my-5 d-flex justify-center">
                                    <span>No FAQ Found</span>
                                </div>
                            ) : (
                                <div className='flex flex-col gap-3 w-full'>
                                    {faqs?.map((faq, index) => (
                                        <div key={index} style={{
                                            border: `1px solid ${openIndex === index ? '#F6921E' : '#b4b4b4'}`
                                        }} className={`be_white rounded-4 shadow-sm`}>
                                            <button
                                                onClick={() => toggle(index)}
                                                className={`w-full flex justify-between items-center ${openIndex === index ? 'text_primary2' : 'text_primary'} p-3 plusJakara_semibold focus:outline-none`}
                                            >
                                                {faq.title}
                                                <FontAwesomeIcon
                                                    icon={openIndex === index ? faChevronUp : faChevronDown}
                                                    className="ml-2 transition-transform duration-300"
                                                />
                                            </button>
                                            <div
                                                className={`transition-all duration-700 ease-in-out overflow-hidden ${openIndex === index ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                                                    }`}
                                            >
                                                <div className="p-3">
                                                    <div
                                                        className="text_black plusJakara_medium"
                                                        dangerouslySetInnerHTML={{ __html: faq.description }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {lastId < count && (
                            <div className="flex justify-center w-full mt-5">
                                <button
                                    onClick={handleShowMore}
                                    className="bg-blue-500 py-2 px-4 rounded text-white font-semibold"
                                >
                                    Show More
                                </button>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </main>
    );
};

export default Faq;