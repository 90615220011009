/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Skeleton } from 'antd';
import '../../style/swiper.css';
import { getBlogs } from '../../api/blogs';
import BlogItem from '../blogItem';
import { setBlogData } from '../../redux/services';
import { FaArrowRight } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

const Blogs = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const blogs = useSelector((state) => state.services?.blogData);
    const [loading, setLoading] = useState(false);

    const fetchBlogs = async () => {
        if (blogs?.length > 0) return;
        setLoading(true);
        try {
            const res = await getBlogs('all', 1);
            const fetchedBlogs = res?.success ? res?.blogs : [];
            dispatch(setBlogData(fetchedBlogs));
        } catch (error) {
            console.error('Failed to fetch blogs:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBlogs();
    }, []);

    return (
        <Container className="py-4 py-md-5">
            <div className="flex flex-col gap-2 mb-4 w-full">
                <h6 className="text-xl md:text-3xl min-[1900px]:text-4xl mb-0 plusJakara_bold text_black">
                    Latest Blog Articles
                </h6>
                <h6 className="mb-0 plusJakara_regular text_black">
                    Get Professional guidence and questions answered by industry experts for your project access.
                </h6>
            </div>
            {loading ? (
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-5">
                    {Array.from({ length: 3 }).map((_, i) => (
                        <div className="bg_white flex flex-col w-full rounded-4 overflow-hidden border relative animate-pulse">
                            <div className="relative h-48 w-full bg-[#f4f4f4]">
                            </div>
                            <div className="p-3 flex gap-2 flex-col w-full">
                                <div className="h-6 w-3/4 bg-[#ccc] rounded"></div>
                                <div className="flex gap-2 items-center mt-2">
                                    <div className="h-5 w-1/4 bg-[#ccc] rounded"></div>
                                    <div className="h-5 w-1/6 bg-[#ccc] rounded"></div>
                                </div>
                                <div className="h-4 w-full bg-[#ccc] rounded mt-2"></div>
                                <div className="h-4 w-5/6 bg-[#ccc] rounded"></div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : blogs?.length === 0 ? (
                <div className="flex justify-center items-center w-full my-5">
                    <span className="plusJakara_medium md:text-lg">No Blogs Found</span>
                </div>
            ) : (
                <div className="gridWork1 w-full relative">
                    {/* <div className="items_swiper w-full relative"> */}
                    {/* <div className="flex justify-between absolute top-0 w-full flex-wrap gap-3 items-start">
                    </div> */}
                    {/* <Swiper
                        freeMode
                        modules={[FreeMode, Navigation]}
                        navigation
                        breakpoints={{
                            320: { slidesPerView: 1 },
                            768: { slidesPerView: 2 },
                            1024: { slidesPerView: 3 },
                        }}
                        className="mySwiper d-flex w-100 gap-3 relative"
                    > */}
                    {blogs?.slice(0, 3)?.map((blog, i) => (
                        // <SwiperSlide
                        //     key={i}
                        //     style={{ height: 'auto' }}
                        //     className="relative pe-4 gap-2"
                        // >
                        <BlogItem category={blog} />
                        // </SwiperSlide> 
                    ))}
                    {/* <SwiperSlide>
                            <div className='flex w-full items-center justify-center' style={{ height: '350px', width: '200px' }}>
                                <button
                                    onClick={() => navigate('/blog')}
                                    style={{ border: '1px solid #F6921E' }}
                                    className="text_primary2 flex gap-2 items-center max-md:text-xs justify-center rounded-2 plusJakara_medium py-[6px] px-2 px-md-3 mt-3"
                                >
                                    See More <FaArrowRight />
                                </button>
                            </div>
                        </SwiperSlide> */}
                    {/* </Swiper> */}
                </div>
            )}
        </Container>
    );
};

export default Blogs;
