/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { Col, Container, Row } from "reactstrap";

import moment from "moment";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BlogCategories from "./homeComponents2/blogCategories";

const BlogDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()
  const blogData = state?.blogData || null

  const handleSelectCategory = (category) => {
    navigate('/blog', { state: { categoryData: category } })
  };

  return (
    <main>
      <Helmet>
        <title>{blogData?.seo?.title}</title>
        <meta
          name="description"
          content={
            blogData?.seo?.description ||
            "Read our in-depth articles and tutorials on math calculations. Learn about Comparing Fractions, Critical Points, and more!"
          }
        />
        <meta
          name="keywords"
          content={`${blogData?.seo?.keywords}`}
        />
      </Helmet>

      <div className="bg-[#FAFAFA] pt-4 pb-3 mt-5">
        <BlogCategories
          // showTitle={false}
          onSelectCategory={handleSelectCategory}
          selectedCategory={blogData?.cat}
        />
      </div>
      <Container className="py-3">
        <Row>
          <Col lg={12} md={12}>
            <h3 className="title plusJakara_medium text-xl md:text-2xl mb-3">
              {blogData?.title}
            </h3>
            <div className="my-4">
              <h6 className="text-end text-muted">{moment(blogData?.createdAt).format('MMMM DD, YYYY')}</h6>
            </div>
            <img src={blogData?.image} style={{ height: "25rem", width: '100%', objectFit: 'cover', borderRadius: '8px' }} alt="" />
            <div className=" section-title">
              <Row>
                <Col lg={24} className="section-title">
                  <div dangerouslySetInnerHTML={{ __html: blogData?.description }} className="mt-3 plusJakara_regular blogContent"></div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

      </Container>
    </main >
  );
}
export default BlogDetail
