/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { allHomeBanners } from '../api/coupon'
import { setAboutBanner } from '../redux/services'
import HeaderNavigationImage from './common/headerNavigationImage'
import WhatWeOffer from './homeComponents/whatWeOffer'

const About = () => {
    const aboutBanner = useSelector((state) => state.services?.aboutBanner);
    const dispatch = useDispatch();

    const fetchData = useCallback(
        debounce(async () => {
            try {
                const res = await allHomeBanners('about', '');
                if (res?.data) {
                    dispatch(setAboutBanner(res?.data?.banners));
                }
            } catch (error) {
                console.error("Error fetching banners", error);
            }
        }, 500),
        []
    );

    useEffect(() => {
        if (!aboutBanner?.length) {
            fetchData();
        } else {
            dispatch(setAboutBanner(aboutBanner));
        }
        return () => {
            fetchData.cancel();
        };
    }, [aboutBanner]);

    return (
        <main className='pb-5'>
            <HeaderNavigationImage data={aboutBanner[0]} />
            <Container className='py-5'>
                <h1 className="plusJakara_semibold text-4xl lg:text-5xl text-center text_dark">Our Story</h1>
                <div className="flex flex-col items-center gap-1 my-5 w-full justify-center">
                    <div style={{ border: '1.13px solid #F45734', width: '100px', height: '2px' }}></div>
                    <div style={{ border: '1.13px solid #F45734', width: '100px', height: '2px' }}></div>
                </div>
                <div className="flex justify-between flex-wrap mb-5 flex-md-nowrap gap-2 gap-md-5 items-center">
                    <span className="text_secondary plusJakara_regular">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus lorem id penatibus imperdiet. Turpis egestas ultricies purus auctor tincidunt lacus nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus lorem id penatibus imperdiet. Turpis egestas ultricies purus auctor tincidunt lacus nunc. </span>
                    <span className="text_secondary plusJakara_regular">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus lorem id penatibus imperdiet. Turpis egestas ultricies purus auctor tincidunt lacus nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus lorem id penatibus imperdiet. Turpis egestas ultricies purus auctor tincidunt lacus nunc. </span>
                </div>
                <h1 className="plusJakara_semibold text-4xl lg:text-5xl text-center text_primary">Why We are the best?</h1>
                <WhatWeOffer />
            </Container>
        </main>
    )
}

export default About