/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../style/main.css';
import EmployeeForm from './employeeForm';
import UpdrageModal from '../common/upgradeModal';

const AddEmployee = ({ showButtons = true }) => {
    const { state } = useLocation();
    const estimateData = state?.estimateData || null;
    const navigate = useNavigate();
    const usertype = useSelector((state) => state.auth?.userData?.type);
    const [modal, setmodal] = useState(false)
    const userData = useSelector((state) => state.auth?.userData)

    useEffect(() => {
        if (userData?.sub_type !== 'premium') {
            setmodal(true)
        } else {
            setmodal(false)
        }
    }, [userData])

    return (
        <main className='p-3 p-md-5 w-full flex justify-center'>
            <Container className={`${modal && 'blur-sm'} p-4 bg_white rounded-4 w-ful`}>
                <div className="flex flex-col gap-3 w-full">
                    <div className="flex gap-4 items-start w-full">
                        <button
                            onClick={() => navigate(-1)}
                            className="bg_primary rounded-3 p-2"
                        >
                            <FaArrowLeft className='text_white' />
                        </button>
                        <h4 className="text_primary mb-0 plusJakara_semibold">Create Employee</h4>
                    </div>
                </div>
                {/* <hr style={{ color: "#828282" }} /> */}
                <EmployeeForm />
            </Container>

            <UpdrageModal setmodalOpen={setmodal} modalOpen={modal} />
        </main>
    );
};

export default AddEmployee;
