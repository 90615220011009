import { createSlice } from '@reduxjs/toolkit';

export const serviceSlice = createSlice({
  name: 'services',
  initialState: {
    serviceData: {},
    categories: [],
    services: [],
    homeBanners: [],
    coupons: [],
    blogData: [],
    blogBanner: [],
    serviceBanner: [],
    blogCategories: [],
    invoiceData: {},
    pagesCount: {},
    providersData: [],
    aboutBanner: [],
    contactBanner: [],
    termsBanner: [],
    privacyBanner: [],
    subscriptionBanner: [],
    faqBanner: [],
  },
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setBlogCategories: (state, action) => {
      state.blogCategories = action.payload;
    },
    setCoupons: (state, action) => {
      state.coupons = action.payload;
    },
    setservices: (state, action) => {
      state.services = action.payload;
    },
    setBlogData: (state, action) => {
      state.blogData = action.payload;
    },
    setHomeBanners: (state, action) => {
      state.homeBanners = action.payload;
    },
    setServiceBanner: (state, action) => {
      state.serviceBanner = action.payload;
    },
    setBlogBanner: (state, action) => {
      state.blogBanner = action.payload;
    },
    setProvidersData: (state, action) => {
      state.providersData = action.payload;
    },
    setInvoiceData: (state, action) => {
      state.invoiceData = action.payload;
    },
    setServiceData: (state, action) => {
      state.serviceData = action.payload;
    },
    setPagesCount: (state, action) => {
      state.pagesCount = action.payload;
    },
    // Additional reducers
    setAboutBanner: (state, action) => {
      state.aboutBanner = action.payload;
    },
    setContactBanner: (state, action) => {
      state.contactBanner = action.payload;
    },
    setTermsBanner: (state, action) => {
      state.termsBanner = action.payload;
    },
    setPrivacyBanner: (state, action) => {
      state.privacyBanner = action.payload;
    },
    setSubscriptionBanner: (state, action) => {
      state.subscriptionBanner = action.payload;
    },
    setFaqBanner: (state, action) => {
      state.faqBanner = action.payload;
    },
  },
});

export const {
  setServiceData,
  setCoupons,
  setBlogCategories,
  setServiceBanner,
  setBlogBanner,
  setBlogData,
  setInvoiceData,
  setservices,
  setProvidersData,
  setPagesCount,
  setHomeBanners,
  setCategories,
  setAboutBanner,
  setContactBanner,
  setTermsBanner,
  setPrivacyBanner,
  setSubscriptionBanner,
  setFaqBanner,
} = serviceSlice.actions;

export default serviceSlice.reducer;
