/* eslint-disable no-unused-vars */
import { Spinner } from 'react-bootstrap';
import { Col, message, Modal, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaArrowLeft, FaStar } from 'react-icons/fa6';
import { MdInfo, MdLocationOn } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { BsChatFill } from "react-icons/bs";
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { createOrder } from '../api/order';
import { deleteService } from '../api/service';
import { avataruser, coveravatar } from '../icons/icon';
import { setServiceProvider } from '../redux/generalRedux';
import { encryptData } from '../redux/localStorageSecure';
import '../style/swiper.css';
import HeaderNavigation from './common/headerNavigation';
const ServiceDetail = () => {
    const { state } = useLocation()
    const { searchParams } = useSearchParams()
    const dispatch = useDispatch()
    const [activeIndex, setActiveIndex] = useState(0);
    const swiperRef = useRef()
    const serviceDetail = state?.serviceDetail || null
    const navigate = useNavigate()
    const [selectedDates, setSelectedDates] = useState([]);
    const [highlightedDate, setHighlightedDate] = useState(null);
    const [grandTotal, setGrandTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showDelete, setshowDelete] = useState(false)
    const [deleteData, setdeleteData] = useState(null)
    const [loading, setloading] = useState(false)
    const isLogin = useSelector((state) => state.auth?.isLogin)
    const usertype = useSelector((state) => state.auth?.userData?.type)

    const handleDeleteModal = (row) => {
        setshowDelete(true)
        setdeleteData(row)
    }

    const handleThumbnailClick = (item, index) => {
        setActiveIndex(index);
        swiperRef.current.slideTo(index);
    };

    const handledelete = async () => {
        setloading(true)
        await deleteService(deleteData?._id).then((res) => {
            if (res.service) {
                message.success('Service deleted successfully')
                setloading(false)
                setshowDelete(false)
            } else {
                setloading(false)
                setshowDelete(false)
                message.error('Failed to delete service')
            }
        }).catch((err) => {
            setloading(false)
            setshowDelete(false)
            console.error("Error deleting service:", err);
        })
    }

    useEffect(() => {
        if (serviceDetail?.availablity) {
            const dates = serviceDetail?.availablity?.map(dateString => moment(dateString).toDate());
            setSelectedDates(dates);
        }
        if (serviceDetail?.features) {
            const total = serviceDetail?.features?.reduce((sum, feature) => sum + (feature.totalPrice || 0), 0);
            setGrandTotal(total);
        }
    }, [serviceDetail]);

    const handleDateSelect = (date) => {
        setHighlightedDate(date);
    };

    const handleNavigate = (item) => {
        const params = new URLSearchParams(searchParams)
        const data = {
            user: item?.user,
        }
        params.set('detail-user', encryptData(data))
        navigate(`/chat?${params.toString()}`)
    }

    const customDayClassName = (date) => {
        const isAvailable = selectedDates.some(d => moment(d).isSame(date, 'day'));
        const isHighlighted = moment(date).isSame(highlightedDate, 'day');
        if (isHighlighted) {
            return 'react-datepicker__day--highlighted';
        }
        return isAvailable ? '' : 'react-datepicker__day--disabled';
    };

    const handleSubmit = async (values) => {
        if (!isLogin) {
            message.error('Please login to book service')
            navigate('/login')
            return
        } else if (!highlightedDate) {
            message.error('Plesse select available date to book service')
            return
        } else {
            const data = {
                bookingDate: new Date(highlightedDate).toISOString(),
                service: serviceDetail._id,
                type: 'order',
                to_id: serviceDetail?.user._id
            }
            setIsLoading(true)
            await createOrder({ data: data }).then((res) => {
                if (res.success === true) {
                    message.success("Service booked successfully");
                    setIsLoading(false)
                    navigate('/orders');
                } else {
                    message.error("Failed to create Order");
                    setIsLoading(false)
                }
            }).catch((err) => {
                console.error("Error creating Order:", err);
                setIsLoading(false)
            })
        }
    }

    const calculateTotal = () => {
        const tax = (serviceDetail?.totalPrice * Number(serviceDetail?.tax)) / 100;
        const taxedPrice = serviceDetail?.totalPrice + tax;
        return (taxedPrice - serviceDetail?.dis_price)?.toFixed(2);
    };

    return (
        <main className={`${usertype === 'company' ? 'p-3 p-md-5' : 'pb-5'} w-full`}>
            {usertype !== 'company' &&
                <HeaderNavigation mainPage={'/all-services'} subPage='Service Detail' subPageAvail={true} page='Fix Services' serviceName={`${serviceDetail?.name}`} />
            }
            <Container fluid={usertype === 'company'} className='p-4 bg_white rounded-2 w-full'>
                {usertype === 'company' &&
                    <div className="flex justify-between w-full">
                        <div className="flex gap-4 mb-5 items-center w-full">
                            <button
                                onClick={() => navigate(-1)}
                                className="bg_primary rounded-3 p-2"
                            >
                                <FaArrowLeft className='text_white' />
                            </button>
                            <h4 className="text_primary mb-0 plusJakara_semibold">Service Detail</h4>
                        </div>
                    </div>}
                <div className="flex gap-3 detail_page_swiper flex-col mb-5 justify-start w-full">
                    <Swiper
                        freeMode={true}
                        modules={[FreeMode, Autoplay, Navigation]}
                        navigation={true}
                        pagination={{ clickable: true }}
                        loop={true}
                        className="mySwiper d-flex w-full gap-3 relative"
                        slidesPerView={1}
                        onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                        onSwiper={(swiper) => (swiperRef.current = swiper)}
                        initialSlide={activeIndex}
                    >
                        {serviceDetail?.images?.map((image, index) => (
                            <SwiperSlide
                                key={index}
                                className='rounded-2'
                                style={{ width: '100%', height: '25rem', overflow: 'hidden' }}
                            >
                                <div className="bg-overlay"></div>
                                <img
                                    src={image || coveravatar}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        cursor: 'pointer',
                                        objectFit: 'contain',
                                        backgroundColor: "#f4f4f4"
                                    }}
                                    alt="Project"
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="thumbnails flex justify-start gap-2 mt-2">
                        {serviceDetail?.images?.map((image, index) => (
                            <div
                                key={index}
                                className={`thumbnail relative cursor-pointer overflow-hidden rounded-2 ${activeIndex === index ? 'border-2 border-[#003F7D]' : 'border'}`}
                                onClick={() => handleThumbnailClick(image, index)}
                                style={{
                                    width: '150px',
                                    height: '130px',
                                    overflow: 'hidden',
                                    transition: 'border-color 0.3s ease',
                                }}
                            >
                                <img
                                    src={image || coveravatar} style={{
                                        height: '100%',
                                        width: '100%',
                                        cursor: 'pointer',
                                        objectFit: 'cover',
                                    }} alt="Project" />
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-wrap w-full justify-between">
                        {/* <Col xs={24} lg={11}>
                            <div className="w-full detail_page_swiper overflow-hidden mb-4">
                                <Swiper
                                    spaceBetween={10}
                                    navigation={true}
                                    freeMode={true}
                                    modules={[Navigation, Autoplay, FreeMode, Pagination,]}
                                    className="mySwiper"
                                    slidesPerView={"auto"}
                                >
                                    {serviceDetail && serviceDetail?.images?.map((image, index) => (
                                        <SwiperSlide key={index} className="bg_img w-full">
                                            <div className="bg-overlay rounded-2"></div>
                                            <img src={image} className='object-contain  h-[20rem] md:h-[30rem] overflow-hidden object-center w-full rounded-2' alt="" />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </Col> */}
                        <Col xs={24} lg={12}>
                            <div className="flex items-start w-full my-3 justify-between">
                                <div className="flex flex-col gap-2 w-full">
                                    {/* <div style={{ width: 'fit-content', border: '1.5px solid #003F7D' }} className="rounded-5 px-2 py-1 text-xs text_primary plusJakara_medium">{serviceDetail?.category?.name || 'Not found'}</div> */}
                                    <h2 className="plusJakara_semibold whitespace-nowrap mb-0 text_black">{serviceDetail?.name}</h2>
                                    {/* <div className="flex gap-2 items-center w-full flex-wrap">
                                        {serviceDetail?.subcategory?.map((item, i) => (
                                            <span key={i} className="text_secondary2 text-sm plusJakara_medium">
                                                {item?.name}
                                                {i < serviceDetail.subcategory.length - 1 && (
                                                    <span className="mx-1"> →</span>
                                                )}
                                            </span>
                                        ))}
                                    </div> */}
                                    <div className="flex gap-2 my-3 flex-wrap items-center">
                                        {serviceDetail?.dis_price && (
                                            <h5 className='plusJakara_medium mb-0 text_secondary2' style={{ textDecoration: 'line-through', }}>
                                                ${serviceDetail?.totalPrice}
                                            </h5>
                                        )}
                                        <h3 className="plusJakara_semibold whitespace-nowrap mb-0 text_primary">
                                            ${calculateTotal()}
                                        </h3>
                                        <h6 className='mb-0 whitespace-nowrap plusJakara_medium' style={{ color: '#555' }}>  (Includes {serviceDetail?.tax}% tax
                                            {serviceDetail?.dis_price && `, $${serviceDetail?.dis_price} discount`})</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-1 mb-3 w-full">
                                <h5 className="plusJakara_semibold whitespace-nowrap mb-0 text_dark">Description:</h5>
                                <span className="text_secondary2 plusJakara_regular">{serviceDetail?.description}</span>
                            </div>
                            <div className="flex flex-col gap-2 w-full">
                                <h5 className="plusJakara_semibold whitespace-nowrap mb-0 text_dark">Available Cities:</h5>
                                <div className="flex gap-2 w-full">
                                    <div className="flex gap-1 items-center w-full flex-wrap">
                                        {serviceDetail?.cities?.map((item, i) => (
                                            <h6 key={i} className="text_secondary2 mb-0 plusJakara_medium">
                                                {item?.city}
                                                {i < serviceDetail.cities.length - 1 && (
                                                    <span> ,</span>
                                                )}
                                            </h6>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {usertype !== 'company' &&
                                <div className="flex mt-4 flex-col">
                                    <h5 className="text_black plusJakara_medium">Service Provider</h5>
                                    <div className="flex gap-3 w-full items-center overflow-hidden relative">
                                        <img src={serviceDetail?.user?.profilePicture || avataruser} className='w-[120px] h-[120px]' style={{ borderRadius: '20px', objectFit: 'cover', border: '11.57px solid #FFFFFF' }} alt="" />
                                        <div className="flex flex-col items-start">
                                            {/* <div style={{ width: 'fit-content', border: '1.5px solid #003F7D' }} className="rounded-5 px-1 py-[2px] text-[10px] text_primary plusJakara_medium">{serviceDetail?.user?.category?.name || 'Not found'}</div> */}
                                            <h6
                                                onClick={() => {
                                                    navigate(`/services/${serviceDetail?.user?._id}`)
                                                    dispatch(setServiceProvider(serviceDetail?.user))
                                                }}
                                                className="plusJakara_semibold text-lg cursor-pointer hover:text-[#f6921e]">{serviceDetail?.user?.name}</h6>
                                            <div className="flex mb-2 items-center">
                                                {[...Array(5)].map((_, i) => (
                                                    <FaStar
                                                        key={i}
                                                        color={i < serviceDetail?.user?.rating ? '#F6921E' : '#d3d3d3'}
                                                        size={18}
                                                    />
                                                ))}
                                                <span className="plusJakara_regular text-sm whitespace-nowrap text_black">({serviceDetail?.user?.total_reviews} Review)</span>
                                            </div>
                                        </div>
                                        <div className="flex gap-2 w-full">
                                            <button onClick={() => handleNavigate(serviceDetail)} className="bg-[#f4f4f4] text_primary plusJakara_regular text-xs p-2 rounded-full"><BsChatFill size={18} /> </button>
                                            <button
                                                onClick={() => {
                                                    navigate(`/services/${serviceDetail?.user?._id}`)
                                                    dispatch(setServiceProvider(serviceDetail?.user))
                                                }}
                                                className="bg-[#f4f4f4] text_primary plusJakara_regular text-xs p-2 rounded-full"><MdInfo size={18} /></button>
                                        </div>
                                    </div>
                                </div>}
                        </Col>
                        {usertype === 'company' ?
                            <Col xs={24} md={12}>
                                <div className="flex w-full flex-col items-start">
                                    <h5 className="plusJakara_semibold mb-0 text_dark">Working Hours:</h5>
                                    {/* {usertype !== 'company' && */}
                                    <Row gutter={16} className='flex-col gap-3'>
                                        <DatePicker
                                            // selected={highlightedDate}
                                            // onChange={handleDateSelect}
                                            includeDates={selectedDates}
                                            inline
                                            disabled
                                            dayClassName={customDayClassName}
                                            className="bg-blue-800 rounded-md"
                                            calendarClassName="bg-gray-800 text-white"
                                        />
                                    </Row>
                                </div>
                            </Col> : <Col xs={24} md={12}>
                                <h5 className="plusJakara_semibold text-center mt-4 mb-2 text_dark">Book Your Service:</h5>
                                <div className="flex w-full flex-col gap-3 items-center">
                                    <DatePicker
                                        selected={highlightedDate}
                                        onChange={handleDateSelect}
                                        includeDates={selectedDates}
                                        inline
                                        disabled
                                        dayClassName={customDayClassName}
                                        className="bg-gray-800 rounded-md"
                                        calendarClassName="bg-gray-800 text-white"
                                    />
                                    {/* {highlightedDate &&
                                    <button disabled={isLoading} onClick={handleSubmit} style={{ padding: '14px 4rem', maxWidth: '500px', width: '100%' }} className="bg_primary max-md:text-xs text_white rounded-3 plusJakara_medium">{isLoading ? <Spinner size="sm" style={{ color: 'white' }} /> : 'Book Now'}</button>} */}
                                </div>
                            </Col>}
                    </div>
                </div>
                {usertype === 'company' &&
                    <div className="flex w-full h-fit justify-end gap-3">
                        <button onClick={() => handleDeleteModal(serviceDetail)} className="bg-red-500 text_white w-fit rounded-3 py-[12px] text-sm px-5 h-fit plusJakara_medium">Delete</button>
                        <button onClick={() => navigate(`/service-list/update/${serviceDetail?._id}`, { state: { serviceData: serviceDetail } })} className="bg_primary text_white w-fit h-fit rounded-3 py-[12px] text-sm px-5 plusJakara_medium">Edit</button>
                    </div>}
                <div className="flex justify-center w-full items-center mt-4">
                    <button disabled={isLoading || !highlightedDate} onClick={handleSubmit} style={{ padding: '14px 4rem', maxWidth: '420px', width: '100%' }} className={`${highlightedDate ? 'bg_primary' : 'bg-[#b4b4b4]'} max-md:text-xs text_white rounded-3 plusJakara_medium`}>{isLoading ? <Spinner size="sm" style={{ color: 'white' }} /> : 'Book Now'}</button>
                </div>
            </Container>
            <Modal open={showDelete} onCancel={() => setshowDelete(false)} centered footer={false}>
                <div className="flex flex-col gap-1 items-center text-center">
                    <h4 className="text_primary mb-0 plusJakara_bold">Delete Service</h4>
                    <h6 className="text_secondary2 mb-0 plusJakara_regular">Are you sure you want to delete this service?</h6>
                    <div className="flex gap-3 mt-3 items-center justify-center w-full">
                        <button
                            onClick={handledelete}
                            disabled={loading}
                            className="bg-red-500 px-5 py-2 rounded-3 text_white plusJakara_medium">
                            {loading ? <Spinner size="sm" style={{ color: "white" }} /> : 'Delete'}
                        </button>
                    </div>
                </div>
            </Modal>
        </ main >
    );
};
export default ServiceDetail;
