/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import HeaderNavigation from './common/headerNavigation';
import { getStaticPage } from '../api/category';
import { Spinner } from 'react-bootstrap';
import { setPrivacyBanner } from '../redux/services';
import { allHomeBanners } from '../api/coupon';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import HeaderNavigationImage from './common/headerNavigationImage';

const PrivacyPolicy = () => {
    const [loading, setLoading] = useState(false);
    const [privacy, setPrivacy] = useState([]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const res = await getStaticPage('privacy')
            if (res?.data) {
                setLoading(false);
                setPrivacy(res?.data?.privacy);
            } else {
                setLoading(false);
                setPrivacy([]);
            }
        }
        catch (error) {
            setLoading(false);
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    const privacyBanner = useSelector((state) => state.services?.privacyBanner);
    const dispatch = useDispatch();

    const fetchBanner = useCallback(
        debounce(async () => {
            try {
                const res = await allHomeBanners('privacy', '');
                if (res?.data) {
                    dispatch(setPrivacyBanner(res?.data?.banners));
                }
            } catch (error) {
                console.error("Error fetching banners", error);
            }
        }, 500),
        []
    );

    useEffect(() => {
        if (!privacyBanner?.length) {
            fetchBanner();
        } else {
            dispatch(setPrivacyBanner(privacyBanner));
        }
        return () => {
            fetchBanner.cancel();
        };
    }, [privacyBanner]);

    return (
        <main className="pb-5">
            <HeaderNavigationImage data={privacyBanner[0]} />
            <Container className="my-5" fluid="xxl">
                {loading ? (
                    <main className="my-5 d-flex w-100 justify-content-center align-items-center">
                        <Spinner className="text_dark" />
                    </main>
                ) : !privacy || privacy.length === 0 ? (
                    <main className="my-5 d-flex w-100 justify-content-center align-items-center">
                        <span className="text_secondary plusJakara_medium">No Content Found</span>
                    </main>
                ) : (
                    <div className="bg_white rounded-3 p-4 ">
                        <div className="plusJakara_regular" dangerouslySetInnerHTML={{ __html: privacy?.des }}></div>
                    </div>
                )}

            </Container>
        </main>
    );
}

export default PrivacyPolicy;