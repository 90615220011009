/* eslint-disable no-unused-vars */
import { message, Modal } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { TbCopy, TbCopyCheck } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { coveravatar } from '../../icons/icon';

const CouponCard = ({ category }) => {
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [copyIcon, setCopyIcon] = useState(() => TbCopy);
    const isLogin = useSelector((state) => state.auth?.isLogin)

    const openModal = (category) => {
        setModalData(category);
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setModalData(null);
    };

    const handleCopy = (code) => {
        navigator.clipboard.writeText(code);
        setCopyIcon(() => TbCopyCheck)
        message.success('Code Copied!');
        setTimeout(() => {
            setCopyIcon(() => TbCopy)
        }, 2000);
    };

    return (
        <div className="bg_white flex w-full rounded-2 py-1 md:py-[6px] px-3 max-h-32 md:min-h-40 items-center overflow-hidden border relative" >
            <div className="relative overflow-hidden">
                <img src={category?.image || coveravatar} style={{ cursor: 'pointer', objectFit: 'cover' }} className='relative h-20 md:h-32 w-32 md:w-56 xl:w-80 rounded-3 overflow-hidden' alt="" />
            </div>
            <div className="flex flex-col p-2 p-md-3 gap-1 gap-md-2 w-full z-10 relative items-start">
                <h5 className="plusJakara_semibold text-[16px] md:text-lg line-clamp-1 mb-0 text_black">
                    {category?.title}
                </h5>
                <h6 className="plusJakara_bold mb-0 text_primary">
                    {category?.type === 'amount' && '$'}{category?.discount}{category?.type === 'percentage' && '%'} Discount
                </h6>
                <span className="plusJakara_medium text-xs mb-0 text_secondary2">
                    Valid Until {moment(category?.expiery_date).format('DD MMM,YYYY')}
                </span>
                <button onClick={() => openModal(category)} className="bg_primary text_white w-fit text-xs rounded-5 py-1 py-md-2 px-2 px-md-3 plusJakara_medium">
                    Get Discount
                </button>
            </div>

            <Modal
                open={isModalVisible}
                onCancel={closeModal}
                footer={null}
                centered
            >
                {modalData && (
                    <div>
                        <h5 className="plusJakara_bold text_primary mb-3">Coupon Details</h5>
                        <img
                            src={modalData?.image}
                            alt={modalData?.title}
                            className=' h-36 md:h-48'
                            style={{
                                width: '100%',
                                objectFit: 'cover',
                                borderRadius: '10px',
                                marginBottom: '1rem',
                            }}
                        />
                        <h5 className="plusJakara_semibold mb-3">{modalData?.title}</h5>
                        <h6 className="plusJakara_semibold text_primary">Get Discount</h6>
                        <h6 className="plusJakara_medium text-sm text_secondary2 mb-3">
                            {modalData?.type === 'amount' && '$'}{modalData?.discount}{modalData?.type === 'percentage' && '%'} Discount
                        </h6>
                        <h6 className="plusJakara_semibold text_primary">Expire On</h6>
                        <h6 className="plusJakara_medium text-sm text_secondary2 mb-3">
                            {moment(modalData?.expirey_date).format('DD MMM,YYYY')}
                        </h6>
                        <h6 className="plusJakara_semibold text_primary">Description</h6>
                        <h6 className="plusJakara_medium text-sm text_secondary2 mb-3">
                            {modalData?.des || 'No additional details available.'}
                        </h6>
                        <button
                            onClick={() => handleCopy(modalData?.code)}
                            className="text_black w-full flex gap-4 items-center justify-center rounded-3 py-[12px] border shadow-sm px-4"
                        >
                            <h5 className="plusJakara_medium text-lg md:text-xl mb-0 text_black">
                                Code: {modalData?.code}
                            </h5>
                            {React.createElement(copyIcon, { size: 24 })}
                        </button>
                    </div>
                )}
            </Modal>
        </div>
    )
}

export default CouponCard