/* eslint-disable no-unused-vars */
import { message } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Eye, EyeOff } from "react-feather";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { getCategory, getSubcat } from "../../api/category";
import { checkEmail, checkPhone } from "../../api/signup";
import { createEmployee } from "../../api/employee";

const EmployeeForm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isLoading, setisLoading] = useState(false)
    const [phone, setphone] = useState('')
    const [phoneMessage, setphoneMessage] = useState('')
    const [invalidPhone, setinvalidPhone] = useState(false)
    const [email, setemail] = useState('')
    const [emailMessage, setemailMessage] = useState('')
    const [invalidEmail, setinvalidEmail] = useState(false)
    const [categories, setcategories] = useState([])
    const [subcategories, setsubcategories] = useState([])
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleFetchCategories = async () => {
        await getCategory()
            .then((res) => {
                if (res.success === true) {
                    setcategories(res.categories)
                }
            })
            .catch((err) => {
            })
    };

    useEffect(() => {
        handleFetchCategories()
    }, [])

    const handlecategorySelect = async (row) => {
        await getSubcat(row?.value)
            .then((res) => {
                if (res.success === true) {
                    setsubcategories(res.subcategories)
                } else {
                    setsubcategories([])
                }
            })
            .catch((err) => {
            })
    }

    const handlePhoneBlur = async (e) => {
        const phone = e.target.value.replace(/[^0-9]/g, '');
        if (!phone) {
            setinvalidPhone(true)
            setphoneMessage('Phone number must not be empty')
            return;
        } else {
            try {
                const res = await checkPhone('+' + phone);
                if (res.success === true) {
                    setinvalidPhone(false)
                    setphone("+" + phone)
                } else {
                    setinvalidPhone(true)
                    setphoneMessage('Phone already exists')
                    setphone("+" + phone)
                    return
                }
            } catch (err) {
                setinvalidPhone(true)
                setphoneMessage('Phone already exists')
                setphone("+" + phone)
                return
            }
        };
    };

    const emailChange = (e) => {
        const email = e.target.value.trim();
        setemail(email);
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(email)) {
            setinvalidEmail(true);
            setemailMessage('Please enter a valid email format');
        } else {
            setemail(email)
        }
    }

    const handleEmailBlur = async (e) => {
        validation.handleBlur(e);
        const email = e.target.value.trim();
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!email) {
            setinvalidEmail(true)
            setemailMessage('Email must not be empty')
            return;
        } else if (!emailPattern.test(email)) {
            setinvalidEmail(true);
            setemailMessage('Please enter a valid email format');
            return;
        } else {
            try {
                const res = await checkEmail(email);
                if (res.success === true) {
                    setinvalidEmail(false)
                    setemail(email)
                } else {
                    setinvalidEmail(true)
                    setemailMessage('Email already exists')
                    setemail(email)
                    return
                }
            } catch (err) {
                setinvalidEmail(true)
                setemailMessage('Email already exists')
                setemail(email)
                return
            }
        };
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            category: null,
            subcategory: [],
            name: "",
            email: "",
            password: "",
            confirmPassword: "",
            phone: ""
        },
        validationSchema: Yup.object({
            name: Yup.string("Enter your Full Name").required("Full Name is required"),
            // email: Yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
            // phone: Yup.string()
            //     .required("Phone number is required")
            //     .matches(/^\+?[0-9]{10,15}$/, "Phone number is not valid")
            //     .min(10, "Phone number must be at least 10 digits")
            //     .max(15, "Phone number must be at most 15 digits"),
            password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .max(15, 'Maximum 15 characters allowed')
                .required("Please Enter Password"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Please confirm your password')
        }),

        onSubmit: async (values, { resetForm }) => {
            setisLoading(true);
            const data = {
                name: values?.name,
                email: email,
                password: values.password,
                phone: phone,
                category: values.category.value,
                subcategories: values.subcategory.map((item) => item.value),
            };
            try {
                setisLoading(true);
                const res = await createEmployee({ data: data });
                if (res.user) {
                    message.success(res.message);
                    navigate('/employee-list', { replace: true });
                }
            } catch (err) {
                message.error('Error sending code: ' + err.message);
            } finally {
                setisLoading(false);
            }
        }
    });

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}
            className="w-full mt-4 auth_form">
            <Row>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="name">
                            Full name
                        </Label>
                        <Input
                            type="text"
                            className="form-control rounded-3 text_secondary2 plusJakara_medium"
                            name="name"
                            id="name"
                            style={{ padding: '14px' }}
                            placeholder="Enter Full Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                                validation.touched.name && validation.errors.name ? true : false
                            }
                        />
                        {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="w-100 mb-3">
                        <Label className="form-label text_secondary2 max-md:text-sm plusJakara_regular" htmlFor="phone">
                            Phone Number
                        </Label>
                        <div className="d-flex tab-content w-full justify-content-center flex-column align-items-center gap-1">
                            <PhoneInput
                                country={"us"}
                                placeholder="Phone Number"
                                value={phone}
                                onChange={(value) => setphone(value)}
                                onBlur={handlePhoneBlur}
                                className="phon_inp text_secondarydark inter_medium"
                                disableSearchIcon={true}
                            />
                            {invalidPhone ? (
                                <FormFeedback type="invalid" className="d-block">{phoneMessage}</FormFeedback>
                            ) : null}
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="mb-3">
                <Label className="form-label text_secondary2 max-md:text-sm plusJakara_regular" htmlFor="email">
                    Email
                </Label>
                <Input
                    type="text"
                    className="form-control rounded-3"
                    name="email"
                    id="email"
                    style={{ padding: '12px 16px', }}
                    placeholder="Enter your Email"
                    onChange={emailChange}
                    onBlur={handleEmailBlur}
                    value={email}
                    invalid={invalidEmail}
                />
                {invalidEmail ? (
                    <FormFeedback type="invalid">{emailMessage}</FormFeedback>
                ) : null}
            </div>
            <Row >
                <Col xs={12} lg={6}>
                    <div className="mb-3 position-relative">
                        <Label className="form-label text_secondary2 max-md:text-sm plusJakara_regular" htmlFor="password">
                            Password
                        </Label>
                        <Input
                            type={showPassword ? "text" : "password"}
                            className="form-control rounded-3 relative"
                            name="password"
                            id="password"
                            style={{ padding: '14px' }}
                            placeholder="Enter your password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                                validation.touched.password && validation.errors.password ? true : false
                            }
                        />
                        <span
                            onClick={() => setShowPassword(!showPassword)}
                            style={{
                                position: 'absolute',
                                right: '24px',
                                top: '45px',
                                zIndex: 99,
                                cursor: "pointer"
                            }}
                        >
                            {!showPassword ? <EyeOff size={20} color="#C4C4C4" /> : <Eye size={20} color="#C4C4C4" />}
                        </span>
                        {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3 position-relative">
                        <Label className="form-label text_secondary2 max-md:text-sm plusJakara_regular" htmlFor="confirmPassword">
                            Confirm Password
                        </Label>
                        <Input
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control rounded-3"
                            name="confirmPassword"
                            id="confirmPassword"
                            style={{ padding: '14px' }}
                            placeholder="Please Confirm your Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmPassword || ""}
                            invalid={
                                validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                            }
                        />
                        <span
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            style={{
                                position: 'absolute',
                                right: '24px',
                                top: '45px',
                                zIndex: 99,
                                cursor: "pointer"
                            }}
                        >
                            {!showConfirmPassword ? <EyeOff size={20} color="#C4C4C4" /> : <Eye size={20} color="#C4C4C4" />}
                        </span>
                        {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                            <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3 w-full px-0">
                        <Label className="form-label text_secondary2 max-md:text-sm plusJakara_regular" htmlFor="category">
                            Select your Service Category
                        </Label>
                        <div className="flex flex-col w-full">
                            <Select
                                name="category"
                                id="category"
                                placeholder="Select category"
                                options={categories?.map((item) => ({
                                    label: item.name,
                                    value: item._id,
                                }))}
                                value={validation.values.category}
                                onChange={(selectedOption) => {
                                    handlecategorySelect(selectedOption);
                                    validation.setFieldValue("subcategory", null);
                                    validation.setFieldValue("category", selectedOption);
                                }}
                                onBlur={() => validation.setFieldTouched("category", true)}
                            />
                            {validation.touched.category && validation.errors.category && (
                                <p className="text-danger plusJakara_regular text-sm mt-1">{validation.errors.category}</p>
                            )}
                        </div>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3 w-full px-0">
                        <Label className="form-label text_secondary2 max-md:text-sm plusJakara_regular" htmlFor="subcategory">
                            Select your Service Subcategories
                        </Label>
                        <div className="flex flex-col w-full">
                            <Select
                                name="subcategory"
                                id="subcategory"
                                isMulti
                                placeholder="Select sub category"
                                options={subcategories?.map((item) => ({
                                    label: item.name,
                                    value: item._id,
                                }))}
                                value={validation.values.subcategory}
                                onChange={(selectedOption) => {
                                    validation.setFieldValue("subcategory", selectedOption);
                                }}
                                onBlur={() => validation.setFieldTouched("subcategory", true)}
                            />
                            {validation.touched.subcategory && validation.errors.subcategory && (
                                <p className="text-danger plusJakara_regular text-sm mt-1">{validation.errors.subcategory}</p>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
            <Col lg={12} className="mb-0 mt-3">
                <div className="flex justify-end">
                    <button style={{ width: '180px', padding: '14px' }} className="rounded-2 w-fit bg_primary plusJakara_medium text_white" disabled={isLoading} type="submit">
                        {isLoading ? <Spinner size="sm"></Spinner> :
                            "Create"}
                    </button>
                </div>
            </Col>
        </Form >
    );
};

export default EmployeeForm;
